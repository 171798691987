import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import {
  Layout,
  BlogPosts,
} from '../../components';

const BlogCategoryTemplate = ({ data, pageContext }) => (
  <Layout>
    <div />
    <Container>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to={data.site.config.blogBasePath}>Blog</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to={`${data.site.config.blogBasePath}/categories`}>Categories</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {pageContext.category}
        </BreadcrumbItem>
      </Breadcrumb>
      <BlogPosts posts={data.posts} />
    </Container>
  </Layout>
);

BlogCategoryTemplate.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object.isRequired,
    posts: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
};

export default BlogCategoryTemplate;

export const query = graphql`
  query AllPostsForCategory($category: String!) {
    site {
      ...ConfigBlogBasePath
    }
    posts: allMarkdownRemark(
      filter: {
        frontmatter: {
          categories: {
            in: [$category]
          }
        }
      }
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      edges {
        ...BlogPostExcerpt
      }
    }
  }
`;
